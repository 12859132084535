import Vue, { PropType } from 'vue';
// @ts-ignore
import { getDateText } from '@devhacker/shared/utils/date';
import AppPicture from '@/components/lh-ui/AppPicture/AppPicture.vue';

import ArticleCardActionsWithReactions from '~/components/ArticleCardActionsWithReactions/ArticleCardActionsWithReactions.vue';
import ArticleCardActions from '~/components/ArticleCardActions/ArticleCardActions.vue';
import ShareBlock from '~/components/ShareBlock/ShareBlock.vue';
import TheLink from '~/components/TheLink.vue';
import WidgetsRendererContainer from '~/containers/WidgetsRendererContainer.vue';
import AuthorsExperts from '~/components/AuthorsExperts/AuthorsExperts.vue';
import FavoriteEmitMethodsMixin from '~/mixins/FavoriteEmitMethodsMixin';
import ArticleCardHeader from '~/components/ArticleCard/ArticleCardHeader.vue';

import {
  isAnyRecipePage,
} from '~/router';

export default Vue.extend({
  name: 'ArticleCard',
  components: {
    ArticleCardHeader,
    TheLink,
    ArticleCardActions,
    ArticleCardActionsWithReactions,
    ShareBlock,
    WidgetsRendererContainer,
    AuthorsExperts,
    AppPicture,
  },
  mixins: [FavoriteEmitMethodsMixin],
  provide () {
    return {
      eventPositionShareBlock: () => 'вверху статьи',
    };
  },

  props: {
    article: {
      type: Object as PropType<IArticle>,
      required: true,
    },
    commentsCount: {
      required: true,
      type: Number as PropType<number>,
    },
    user: {
      type: Object as PropType<IUser | null>,
      default: null,
    },
    favorite: {
      type: Object as PropType<IFavoriteItem | null>,
      default: null,
    },
    folders: {
      required: true,
      type: Object as PropType<IFolders>,
    },
    commentsEnabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data () {
    return {
      isShareBlockVisible: false as boolean,
      isErrorWhileLoadingCover: false as boolean,
    };
  },

  computed: {
    isPromo (): boolean {
      return this.article.isPromo;
    },
    imgAlt (): string {
      return this.article.image?.alt || this.title;
    },
    dateText (): string {
      return getDateText(this.article.date);
    },
    isAnyRecipePage (): boolean {
      return isAnyRecipePage(this.$route.name);
    },
    articleCardComponent (): string {
      return this.isAnyRecipePage
        ? 'ArticleCardActions'
        : 'ArticleCardActionsWithReactions';
    },
    computedAuthorText (): string {
      const { article } = this;
      // если партнерский пост, не показывать имя автора
      return article.isAdvertising ? '' : article.author.name;
    },

    sizesSliced (): number[] {
      return this.isPromo
        ? [390, 640, 732, 900, 1350, 1800, 2250]
        : [390, 640, 778, 1170, 1500, 1945];
    },

    sizesByDevice (): any {
      return this.isPromo
        ? { mobile: '100vw', tablet: '732px', bigTablet: '900px' }
        : { mobile: '100vw', tablet: '592px', bigTablet: '638px', desktop: '778px' };
    },
  },

  methods: {
    getDateText,

    onCoverLoadError (): void {
      if (!this.isErrorWhileLoadingCover) {
        this.isErrorWhileLoadingCover = true;
      }
    },
  },
});
